import React, { useContext, useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom';
import { useAuth } from '../../hook/useAuth';
import { observer } from 'mobx-react-lite';
import { Context } from '../../../index';
import { check } from '../../http/userAPI';

const  Map = observer(() => {
  
 


  return (
    <div>Map
     
   
    </div>
  )
})

export default Map